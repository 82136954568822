<template>
	<div id="error404" class="pb-0">
		<v-row class="pa-0 ma-0 error-cont" align="center" no-gutters>
			<v-col cols="12" class="px-0 white--text">
				<div class="mx-auto pb-5 mb-15">
					<h2 class="display-1 mb-3 text-center text-md-h3 shadowed">Whoops!</h2>
					<p class="title mb-0 lh-14 text-center mx-auto mb-3 mb-md-5 shadowed" style="max-width: 600px;">
						We could not find the page you were looking for.
					</p>
					<p class="text-center pt-3 pt-md-0">
						<v-btn color="primary" rounded to="/">Go to Home Page</v-btn>
					</p>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>

export default {
	name: '404ErrorPage',
	data: () => ({
		
	}),
	components: {
	},
	computed: {
	},
	methods: {
		initData(){
		},
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
		
	}
}
</script>